import { 
  REQUEST_GET_ALL_BLOG, 
  SUCCESS_GET_ALL_BLOG,
  REQUEST_CREATE_BLOG,
  SUCCESS_CREATE_BLOG,
  REQUEST_GET_DETAIL_BLOG,
  SUCCESS_GET_DETAIL_BLOG,
  REQUEST_EDIT_BLOG,
  SUCCESS_EDIT_BLOG
} from "./action_types"
import axios from '../../helpers/axios'
import { server } from "../../config/server"

export function requestGetAllBlog(payload) {
  return {
    type: REQUEST_GET_ALL_BLOG,
    payload,
  }
}

export function successGetAllBlog(payload) {
  return {
    type: SUCCESS_GET_ALL_BLOG,
    payload,
  }
}

export function requestGetDetailBlog(payload) {
  return {
    type: REQUEST_GET_DETAIL_BLOG,
    payload,
  }
}

export function successGetDetailBlog(payload) {
  return {
    type: SUCCESS_GET_DETAIL_BLOG,
    payload,
  }
}

export function requestCreateBlog(payload) {
  return {
    type: REQUEST_CREATE_BLOG,
    payload,
  }
}

export function successCreateBlog(payload) {
  return {
    type: SUCCESS_CREATE_BLOG,
    payload,
  }
}

export function requestEditBlog(payload) {
  return {
    type: REQUEST_EDIT_BLOG,
    payload,
  }
}

export function successEditBlog(payload) {
  return {
    type: SUCCESS_EDIT_BLOG,
    payload,
  }
}

export function getAllBlog() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestGetAllBlog())
      axios({
        url: `${server()}/blogs/`,
        method: "GET",
        withCredentials: true,
      })
      .then((data) => {
        if (data) {
          dispatch(successGetAllBlog(data))
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err.response.data)
      })
    })
  }
}

export function createBlog(payload) {
  return (dispatch) => {
    const {
      content,
      blogId,
      title,
      status,
      summary,
      filesThumbnail,
      filesCover,
      categories = []
    } = payload
    const formData = new FormData();
    formData.append("content", content)
    formData.append("blogId", blogId)
    formData.append("title", title)
    formData.append("status", status)
    formData.append("summary", summary)
    formData.append("imgThumbnail", filesThumbnail && filesThumbnail[0])
    formData.append("imgCover", filesCover && filesCover[0])
    formData.append("categories", JSON.stringify(categories))

    return new Promise((resolve, reject) => {
      dispatch(requestCreateBlog())
      axios({
        url: `${server()}/blogs/create`,
        method: "POST", 
        data: formData,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(({data}) => {
          if (data.success) {
            if (data.response) {
              dispatch(successCreateBlog(data.response))
              resolve(data)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}

export function getDetailBlog(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestGetDetailBlog())
      axios({
        url: `${server()}/blogs/${id}`,
        method: "GET",
        withCredentials: true
      })
        .then(({data}) => {
          if (data.success) {
            if (data.response) {
              dispatch(successGetDetailBlog(data.response))
              resolve(data)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}

export function editBlog(payload) {
  return (dispatch) => {
    const {
      id,
      content,
      blogId,
      title,
      status,
      imgThumbnail,
      imgCover,
      summary,
      filesThumbnail,
      filesCover,
      categories = []
    } = payload
    const formData = new FormData();
    formData.append("content", content)
    formData.append("blogId", blogId)
    formData.append("title", title)
    formData.append("status", status)
    formData.append("summary", summary)
    formData.append("categories", JSON.stringify(categories))
    if (filesThumbnail && filesThumbnail.length > 0) {
      formData.append("imgThumbnail", filesThumbnail && filesThumbnail[0])
    } else {
      formData.append("imgThumbnail", imgThumbnail)
    }

    if (filesCover && filesCover.length > 0) {
      formData.append("imgCover", filesCover && filesCover[0])
    } else {
      formData.append("imgCover", imgCover)
    }

    return new Promise((resolve, reject) => {
      dispatch(requestEditBlog())
      axios({
        url: `${server()}/blogs/${id}`,
        method: "PATCH", 
        data: formData,
        withCredentials: true
      })
        .then(({data}) => { 
          if (data.success) {
            if (data.response) {
              dispatch(successEditBlog(data.response))
              resolve(data)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}

export function deleteBlog(id) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${server()}/blogs/${id}`,
        method: "DELETE",
        withCredentials: true
      })
        .then(({data}) => {
          if (data.success) {
            if (data.response) {
              resolve(data)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}