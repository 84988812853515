import FadeLoader from "react-spinners/FadeLoader";

const Loader = ({show}) => {
  return (
    show ? (
      <div className="loader-modal">
        <div className="loader-body">
          <div className="d-flex justify-content-center flex-column">
            <div className="mb-2">
              <FadeLoader
                loading={show}
                size={200}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          <span>Loading.....</span>
          </div>
        </div>
      </div>
    ) : null
  )
}

export default Loader