import { SET_LOGIN } from "./action_types"
import axios from "../../helpers/axios"
import { server } from "../../config/server"
import Cookies from "js-cookie"

export function setLogin(payload) {
  return {
    type: SET_LOGIN,
    payload,
  }
}

export function loggedIn(payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${server()}/users/login`,
        method: "POST",
        data: payload,
      })
        .then(({ data }) => {
          if (data.success) {
            dispatch(setLogin(data))
            resolve(data)
          }
        })
        .catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}

export function registered(payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      axios({
        url: `${server()}/users/register`,
        method: "POST",
        data: payload,
      })
        .then(({ data }) => {
          if (data.success) {
            resolve(data)
          }
        })
        .catch((err) => {
          console.log(err)
          reject(err.response.data)
        })
    })
  }
}