export const SET_LOGIN = "SET_LOGIN"

export const REQUEST_GET_ALL_BLOG = "REQUEST_GET_ALL_BLOG"
export const SUCCESS_GET_ALL_BLOG = "SUCCESS_GET_ALL_BLOG"

export const REQUEST_GET_DETAIL_BLOG = "REQUEST_GET_DETAIL_BLOG"
export const SUCCESS_GET_DETAIL_BLOG = "SUCCESS_GET_DETAIL_BLOG"

export const REQUEST_CREATE_BLOG = "REQUEST_CREATE_BLOG"
export const SUCCESS_CREATE_BLOG = "SUCCESS_CREATE_BLOG"

export const REQUEST_EDIT_BLOG = "REQUEST_EDIT_BLOG"
export const SUCCESS_EDIT_BLOG = "SUCCESS_EDIT_BLOG"

export const REQUEST_GET_ALL_CATEGORY = "REQUEST_GET_ALL_CATEGORY"
export const SUCCESS_GET_ALL_CATEGORY = "SUCCESS_GET_ALL_CATEGORY"

export const REQUEST_GET_DETAIL_CATEGORY = "REQUEST_GET_DETAIL_CATEGORY"
export const SUCCESS_GET_DETAIL_CATEGORY = "SUCCESS_GET_DETAIL_CATEGORY"

export const REQUEST_CREATE_CATEGORY = "REQUEST_CREATE_CATEGORY"
export const SUCCESS_CREATE_CATEGORY = "SUCCESS_CREATE_CATEGORY"
export const ERROR_CREATE_CATEGORY = "ERROR_CREATE_CATEGORY"

export const REQUEST_EDIT_CATEGORY = "REQUEST_EDIT_CATEGORY"
export const SUCCESS_EDIT_CATEGORY = "SUCCESS_EDIT_CATEGORY"
export const ERROR_EDIT_CATEGORY = "ERROR_EDIT_CATEGORY"

export const REQUEST_DELETE_CATEGORY = "REQUEST_DELETE_CATEGORY"
export const SUCCESS_DELETE_CATEGORY = "SUCCESS_DELETE_CATEGORY"