import { 
  REQUEST_GET_ALL_BLOG, 
  SUCCESS_GET_ALL_BLOG,
  REQUEST_CREATE_BLOG,
  SUCCESS_CREATE_BLOG,
  REQUEST_GET_DETAIL_BLOG,
  SUCCESS_GET_DETAIL_BLOG,
  REQUEST_EDIT_BLOG,
  SUCCESS_EDIT_BLOG
} from "../actions/action_types"

let initialState = {
  blogs: [],
  blog: {},
  loading: false
}

export default function blogReducers (state = initialState, action) {
  let { type, payload } = action
  switch (type) {
    case REQUEST_GET_ALL_BLOG:
      return {...state, loading: true}
    case SUCCESS_GET_ALL_BLOG:
      return {...state, loading: false, blogs: payload.data}
    case REQUEST_GET_DETAIL_BLOG:
      return {...state, loading: true}
    case SUCCESS_GET_DETAIL_BLOG:
      return {...state, loading: false, blog: payload}
    case REQUEST_CREATE_BLOG:
      return {...state, loading: true}
    case SUCCESS_CREATE_BLOG:
      return {...state, loading: false}
    case REQUEST_EDIT_BLOG:
      return {...state, loading: true}
    case SUCCESS_EDIT_BLOG:
      return {...state, loading: false}
    default:
      return state
  }
}