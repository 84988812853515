import React, { useState, useMemo } from "react"
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import HeadMeta from '../components/HeadMeta'

// Images
import CloudxierLogoSquare from '../assets/image/logo/Cloudxier-logofull-colored-05.png'

import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CInputGroup,
  CFormLabel,
  CFormInput,
  CRow,
  CAlert
} from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'

// CSS
import "./Login.css"

// ACTIONS
import { registered } from '../redux/actions/user_actions'

import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [userRegister, setUserRegister] = useState({
    firstName: "",
    email: "",
    password: "",
    retypePassword: "",
  })

  const [isValidFirstName, setIsValidFirstName] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [isValidPassword, setIsValidPassword] = useState(false)
  const [isValidRetypePassword, setIsValidRetypePassword] = useState(false)
  const [isValidCaptcha, setIsValidCaptcha] = useState(false)

  const [invalidEmailText, setInvalidEmailText] = useState("Please fill this out field")
  const [invalidPasswordText, setInvalidPasswordText] = useState("Please fill this out field")
  const [invalidRetypePasswordText, setInvalidRetypePasswordText] = useState("Please fill this out field")
  const [validPasswordText, setValidPasswordText] = useState("")

  const [firstNameValidated, setFirstNameValidated] = useState(false)
  const [emailValidated, setEmailValidated] = useState(false)
  const [passValidated, setPassValidated] = useState(false)
  const [retypePassValidated, setRetypePassValidated] = useState(false)
  const [captchaValidated, setCaptchaValidated] = useState(false)

  const [showPassword, setShowPassword] = useState(false)
  const [showRetypePassword, setShowRetypePassword] = useState(false)

  const [isErrorRegister, setIsErrorRegister] = useState(false)
  const [errorRegisterText, setErrorRegisterText] = useState("")

  const stylePassIcon = useMemo(() => ({
    ...(passValidated ? {right: '30px'} : {right: '10px'})
  }), [
    passValidated
  ]);

  const styleRetypePassIcon = useMemo(() => ({
    ...(retypePassValidated ? {right: '30px'} : {right: '10px'})
  }), [
    retypePassValidated
  ]);

  const changeInputRegisterHandler = (e) => {
    const value = e.target.value
    const name = e.target.name

    if (name === 'firstName') {
      setFirstNameValidated(true)

      if (value === '' && value.length < 1) {
        setIsValidFirstName(false)
      } else {
        setIsValidFirstName(true)
      }

    }

    if (name === 'email') {
      setEmailValidated(true)

      if (value === '' && value.length < 1) {
        setIsValidEmail(false)
        setInvalidEmailText("Please fill this out field")
      } else {
        const regexCheckEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
        if (!regexCheckEmail.test(value)) {
          setIsValidEmail(false)
          setInvalidEmailText("Please input valid email")
        } else {
          setIsValidEmail(true)
          setInvalidEmailText("")
        }
      }
    }

    if (name === 'password') {
      const regexSpecialChar = /^.*[@$!%*#?&^_-]/;
      const regexUpperCase = /^(?=.*[A-Z])/;
      const regexMinLength = /^.{8,}$/;
      setPassValidated(true)

      if (value === '') {
        setIsValidPassword(false)
        setInvalidPasswordText("Please fill this out field")
        setValidPasswordText("")
      } else {
        if (!regexMinLength.test(value)) {
          setIsValidPassword(false)
          setInvalidPasswordText("Password must be at least 8 characters")
          setValidPasswordText("")
        } else {
          if (!regexSpecialChar.test(value) || !regexUpperCase.test(value)) {
            setIsValidPassword(false)
            setInvalidPasswordText("Password must contain at least one special character and one uppercase")
            setValidPasswordText("")
          } else {
            setIsValidPassword(true)
            setInvalidPasswordText("")
            setValidPasswordText("Looks good!")
          }
        }

        if (regexMinLength.test(value) && regexSpecialChar.test(value) && regexUpperCase.test(value)) {
          setIsValidPassword(true)
          setInvalidPasswordText("")
          setValidPasswordText("Looks good!")
        }
      }
    }

    if (name === 'retypePassword') {
      setRetypePassValidated(true)
      if (value === '' && value.length < 1) {
        setIsValidRetypePassword(false)
        setInvalidRetypePasswordText("Please fill this out field")
      } else {
        if (value !== userRegister.password) {
          setIsValidRetypePassword(false)
          setInvalidRetypePasswordText("Retype password must be match with the first password")
        } else {
          setIsValidRetypePassword(true)
          setInvalidRetypePasswordText("")
        }
      }
    }

    setUserRegister({
      ...userRegister,
      [name]: value,
    })
  }

  const handleValidationRegister = (e) => {
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation()
    }
    setFirstNameValidated(true)
    setEmailValidated(true)
    setPassValidated(true)
    setRetypePassValidated(true)
    setCaptchaValidated(true)

    if (userRegister.password === userRegister.retypePassword && 
        userRegister.password.length > 0 && userRegister.retypePassword.length > 0) {
      if (isValidFirstName && isValidEmail && isValidPassword && isValidRetypePassword) {
        const registerPayload = {
          firstname: userRegister.firstName,
          email: userRegister.email,
          password: userRegister.password
        }
        dispatch(registered(registerPayload))
        .then((data) => {
          if (data && data.success) {
            history.push('/account/verify')
          }
        })
        .catch((err) => {
          setIsErrorRegister(true)
          setErrorRegisterText(err.message)
        })
      }
    }
  }

  const handleSubmitRegister = (e) => {
    e.preventDefault()
    handleValidationRegister(e)
  }

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleRetypePassword = () => {
    setShowRetypePassword(!showRetypePassword)
  }

  const captchaOnChange = (value) => {
    if (value) {
      setIsValidCaptcha(!isValidCaptcha)
    }
  }

  return (
    <div className="bg-white min-vh-100 d-flex flex-row align-items-center">
      <HeadMeta
        title='Admin Panel - Register'
        description='Register to admin panel'
        siteName="Cloudxier"
        url={window.location.href}
        image={CloudxierLogoSquare}
      />
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={5}>
            <div className="p-4">
              <CForm onSubmit={handleSubmitRegister} noValidate>
                <h1 className="text-center">Cloudxier</h1>
                <div className="text-center mb-5 mt-5">
                  <CRow className="justify-content-center">
                    <CCol sm={6} md={5} lg={6} className="switch-btn-register p-2 toggle-icon" style={{backgroundColor: '#336AFE', color: '#FFFFFF'}} onClick={() => history.push('/register')}>
                      <span>Register</span>
                    </CCol>
                    <CCol sm={6} md={5} lg={6} className="switch-btn-login p-2 toggle-icon" style={{backgroundColor: '#F3F3F3', color: '#000000'}} onClick={() => history.push('/login')}>
                      <span>Login</span>
                    </CCol>
                  </CRow>
                </div>
                <div>
                  {
                    isErrorRegister && (
                      <CAlert color="danger" dismissible onClose={() => setIsErrorRegister(false)}>
                        {errorRegisterText}
                      </CAlert>
                    )
                  }
                </div>
                <div className="mb-3">
                  <CFormInput 
                    className="form-input" 
                    label="First Name" 
                    placeholder="" 
                    autoComplete="firstname" 
                    name="firstName" 
                    value={userRegister.firstName} 
                    onChange={changeInputRegisterHandler}
                    id="validationFirstName" 
                    aria-describedby="validationFirstName" 
                    feedbackInvalid="Please fill this out field"
                    feedbackValid="Looks good!"
                    valid={isValidFirstName && firstNameValidated} 
                    invalid={!isValidFirstName && firstNameValidated} 
                    required
                  />
                </div>
                <div className="mb-3">
                  <CFormInput 
                    className="form-input" 
                    type='email' 
                    label="Email Address" 
                    placeholder="yourname@gmail.com" 
                    autoComplete="email" 
                    name="email" 
                    value={userRegister.email} 
                    onChange={changeInputRegisterHandler}
                    id="validationEmail" 
                    aria-describedby="validationEmail" 
                    feedbackInvalid={invalidEmailText} 
                    feedbackValid="Looks good!" 
                    valid={isValidEmail && emailValidated} 
                    invalid={!isValidEmail && emailValidated} 
                    required
                  />
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="basic-url">Password</CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      className="form-input"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="********"
                      autoComplete="current-password"
                      name="password"
                      value={userRegister.password}
                      onChange={changeInputRegisterHandler}
                      id="validationPassword" 
                      aria-describedby="validationPassword"
                      feedbackInvalid={invalidPasswordText}
                      feedbackValid={validPasswordText}
                      valid={isValidPassword && passValidated} 
                      invalid={!isValidPassword && passValidated}
                      required
                    />
                    <span className="password-toggle-icon" onClick={handleShowPassword} style={stylePassIcon}>
                      {
                        showPassword ? 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                        </svg>
                        : 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                        </svg> 
                      }
                    </span>
                  </CInputGroup>
                </div>
                <div className="mb-3">
                  <CFormLabel htmlFor="basic-url">Retype Password</CFormLabel>
                  <CInputGroup>
                    <CFormInput
                      className="form-input"
                      type={showRetypePassword ? 'text' : 'password'}
                      placeholder="********"
                      autoComplete="current-password"
                      name="retypePassword"
                      value={userRegister.retypePassword}
                      onChange={changeInputRegisterHandler}
                      id="validationRetypePassword" 
                      aria-describedby="validationRetypePassword"
                      feedbackInvalid={invalidRetypePasswordText}
                      feedbackValid="Looks good!"
                      valid={isValidRetypePassword && retypePassValidated} 
                      invalid={!isValidRetypePassword && retypePassValidated}
                      required
                    />
                    <span className="password-toggle-icon" onClick={handleRetypePassword} style={styleRetypePassIcon}>
                      {
                        showRetypePassword ? 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-fill" viewBox="0 0 16 16">
                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"/>
                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"/>
                        </svg>
                        : 
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                        </svg> 
                      }
                    </span>
                  </CInputGroup>
                </div>
                <div className="mb-3">
                  <ReCAPTCHA 
                    sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
                    onChange={captchaOnChange}
                  />
                  {
                    !isValidCaptcha && captchaValidated && (
                      <span className="text-danger invalid-text">
                        Captcha is required
                      </span>
                    )
                  }
                </div>
                <div className="text-center">
                  <CButton className="px-5" shape="rounded-pill" type="submit" color="dark">
                    Register
                  </CButton>
                </div>
              </CForm>
            </div>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Register
