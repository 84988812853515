import React, { useEffect, useState } from "react"
import { useHistory, useParams } from 'react-router-dom'
import axios from "../helpers/axios"
import { server } from "../config/server"

import {
  CButton,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'

import Email from "../assets/image/got-email.gif"

const Verify = () => {
  const history = useHistory()
  const params = useParams()
  const token = params && params.token;

  const [isSuccessVerifying, setIsSuccessVerifying] = useState(false);

  useEffect(() => {
    if (token) {
      axios({
        url: `${server()}/users/verify/${token}`,
        method: 'GET',
      }).then(({ data })=> {
        if (data && data.success) {
          setIsSuccessVerifying(true)
        }
      })
    }
  }, [])

  return (
    <div className="bg-white min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={6} className="d-flex flex-column align-items-center justify-content-center">
            {
              token ? (
                isSuccessVerifying ? (
                  <div>
                    <h3>Verification success</h3>
                    <div>
                      You have successfully verified your account. Please login to
                      proceed further.
                    </div>
                    <CButton 
                      color="dark" 
                      className="px-4" 
                      variant="outline" 
                      type="submit"
                      style={{
                        marginTop: "1em",
                        borderWidth: "1.5px",
                      }}
                      onClick={() => history.push('/login')}
                    >
                      <div
                        style={{
                          color: "#000000",
                          fontWeight: "400",
                        }}
                      >
                        Back to login page
                      </div>
                    </CButton>
                  </div>
                ) : (
                  <div>
                    <span className="clearfix">
                      <h4 className="pt-3">
                        Please wait while we verify your account...
                      </h4>
                    </span>
                  </div>
                )
              ) : (
                <div className="p-4">
                  <h2>Verify your account</h2>
                  <div>
                    <img src={Email} width={200} />
                  </div>
                  <span>Account activation link has been sent to the e-mail address you provided</span>
                  <br />
                  <span>Please check your email.</span>
                </div>
              )
            }
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Verify