import React from 'react'
import { CFooter } from '@coreui/react'

const Footer = () => {
  return (
    <CFooter>
      <div>
        <a href="https://www.cloudxier.com/" target="_blank" rel="noopener noreferrer">
          Cloudxier
        </a>
        <span className="ms-1">&copy; 2023.</span>
      </div>
      <div className="ms-auto">
        <span className="me-1">Powered by</span>
        <a href="https://www.cloudxier.com/" target="_blank" rel="noopener noreferrer">
          Cloudxier
        </a>
      </div>
    </CFooter>
  )
}

export default React.memo(Footer)
