import { 
  REQUEST_GET_ALL_CATEGORY,
  SUCCESS_GET_ALL_CATEGORY,
  REQUEST_GET_DETAIL_CATEGORY,
  SUCCESS_GET_DETAIL_CATEGORY,
  REQUEST_CREATE_CATEGORY, 
  SUCCESS_CREATE_CATEGORY,
  ERROR_CREATE_CATEGORY,
  REQUEST_EDIT_CATEGORY,
  SUCCESS_EDIT_CATEGORY,
  ERROR_EDIT_CATEGORY
} from "../actions/action_types"

let initialState = {
  categories: [],
  category: {},
  loading: false
}

export default function blogReducers (state = initialState, action) {
  let { type, payload } = action
  switch (type) {
    case REQUEST_GET_ALL_CATEGORY:
      return {...state, loading: true}
    case SUCCESS_GET_ALL_CATEGORY:
      return {...state, loading: false, categories: payload}
    case REQUEST_GET_DETAIL_CATEGORY:
      return {...state, loading: true}
    case SUCCESS_GET_DETAIL_CATEGORY:
      return {...state, loading: false, category: payload}
    case REQUEST_CREATE_CATEGORY:
      return {...state, loading: true}
    case SUCCESS_CREATE_CATEGORY:
      return {...state, loading: false}
    case ERROR_CREATE_CATEGORY:
      return {...state, loading: false}
    case REQUEST_EDIT_CATEGORY:
      return {...state, loading: true}
    case SUCCESS_EDIT_CATEGORY:
      return {...state, loading: false}
    case ERROR_EDIT_CATEGORY:
      return {...state, loading: false}
    default:
      return state
  }
}