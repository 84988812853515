import { SET_LOGIN } from "../actions/action_types"

let initialState = {
  user: {}
}

export default function userReducers (state = initialState, action) {
  let { type, payload } = action
  switch (type) {
    case SET_LOGIN:
      return {...state, user: payload.user}
    default:
      return state
  }
}