import { combineReducers } from 'redux'

import projects from './project_reducers'
import users from './user_reducers'
import blogs from './blog_reducers'
import categories from './category_reducers'

const reducer = combineReducers({
	users,
	blogs,
	categories
	// projects
})

export default reducer