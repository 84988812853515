import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  CContainer,
} from '@coreui/react'
import { CSmartTable } from '@coreui/react-pro'

//components
import { Sidebar, Header, Footer, Loader } from '../components/index'
import HeadMeta from '../components/HeadMeta'

// ACTIONS
import { getAllBlog, deleteBlog } from '../redux/actions/blog_actions'

// Images
import CloudxierLogoSquare from '../assets/image/logo/Cloudxier-logofull-colored-05.png'

import Swal from 'sweetalert2';

function Home (props) {
  const dispatch = useDispatch()
  const history = useHistory()
  const { blogs, loading } = useSelector((state) => state.blogs);
  
  const columns = [
    {
      key: 'blog_id',
      label: 'Blog ID',
      filter: false
    },
    {
      key: 'title',
      filter: false
    },
    {
      key: 'Posted On',
      filter: false
    },
    {
      key: 'Posted By',
      filter: false
    },
    {
      key: 'status',
      filter: false
    },
    {
      key: 'action',
      filter: false,
      sorter: false,
    }
  ]

  const [showModalDelete, setShowModalDelete] = useState(false)
  const [blogId, setBlogId] = useState(null)
  
  useEffect(() => {
    dispatch(getAllBlog())
  }, [])

  const handleShowModalDelete = (id) => {
    setShowModalDelete(true)
    setBlogId(id)
  }
  useEffect(() => {
    handleShowSwalModal()
  }, [showModalDelete])

  const handleShowSwalModal = () => {
    if (showModalDelete) {
      Swal.fire({
        title: 'Are you sure to deleted this blog?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#336AFE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteBlog(blogId))
            .then(((data) => {
              if (data.success) {
                Swal.fire(
                  'Deleted!',
                  'Your blog has been deleted.',
                  'success'
                ).then(() => {
                  dispatch(getAllBlog())
                })
              }
            }))
        }
        setShowModalDelete(false)
        setBlogId(null)
      })
    }
  }

  
  return (
    <div>
      <HeadMeta
        title='Dashboard'
        description='Manage content blog'
        siteName="Cloudxier"
        url={window.location.href}
        image={CloudxierLogoSquare}
      />
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-white">
        <Header props={props} />
        <div className="body flex-grow-1 px-3">
          <CContainer lg className='p-5'>
            <div className="d-flex">
              <div className="ms-auto">
              <button className="btn mb-3" style={{backgroundColor: '#EFF2F5'}} onClick={() => {history.push('/add-blog')}}>
                + Create new blog
              </button>
              </div>
            </div>
            {
              blogs && Array.isArray(blogs) && blogs.length > 0 ? (
                <CSmartTable 
                  items={blogs}
                  columns={columns}
                  columnSorter
                  pagination
                  itemsPerPage={10}
                  itemsPerPageLabel
                  itemsPerPageOptions	
                  columnFilter={false}
                  tableFilter
                  tableFilterLabel="Search : "
                  tableFilterPlaceholder="Search here..."
                  scopedColumns={{
                    action: (item) => {
                      return (
                        <td className="py-2">
                          <div className="d-flex">
                            <div className="me-2">
                              <span className="toggle-icon" onClick={()=> history.push(`/edit/${item.id}`)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                                  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                                  <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                                </svg>
                              </span>
                            </div>
                            <div>
                              <span className="toggle-icon" onClick={() => handleShowModalDelete(item.id)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                                  <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </td>
                      )
                    }
                  }}
                />
              ) : (
                <div className='justify-content-center text-center'>
                  <span>
                    Blog post not yet available...
                  </span>
                </div>
              )
            }
            <Loader show={loading} />
          </CContainer>
        </div>
        <Footer />
      </div>
    </div>
  )
}

export default Home