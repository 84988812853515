import React from 'react'
import {
  CSidebar,
  CSidebarNav,
  CNavItem,
  CNavTitle
} from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'


function Sidebar () {
  const vars = { 
    '--cui-sidebar-bg': '#4C39FF',
    '--cui-sidebar-nav-title-color': 'white',
    '--cui-sidebar-nav-link-color': 'white',
    '---cui-sidebar-occupy-start': '0',
    '--cui-sidebar-padding-x': '2rem'
  }

  return (
    <CSidebar 
      position="fixed"
      style={vars}
      visible={true}
    >
      <CSidebarNav
      >
        <CNavTitle className="mt-4 mb-5"><h3>Cloudxier</h3></CNavTitle>
        <CNavItem href="/">
          Dashboard
        </CNavItem>
        <CNavItem href="/add-blog">
          Blog posts
        </CNavItem>
        <CNavItem href="/category">
          Blog category
        </CNavItem>
      </CSidebarNav>
    </CSidebar>
  )
}

export default React.memo(Sidebar)
