import React, {useState, useEffect} from 'react';
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

//components
import { Sidebar, Header, Footer, Loader } from '../components/index'
import HeadMeta from '../components/HeadMeta'

import {
  CContainer,
  CForm,
  CFormInput,
  CButton,
  CCol,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
} from '@coreui/react'

import Swal from 'sweetalert2';

// Images
import CloudxierLogoSquare from '../assets/image/logo/Cloudxier-logofull-colored-05.png'

// ACTIONS
import { getAllCategory, getDetailCategory, createCategory, editCategory, deleteCategory } from '../redux/actions/category_actions'

function Category (props) {
  const history = useHistory()
  const dispatch = useDispatch()
  const { categories, loading } = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(getAllCategory())
  }, [])

  const [showCategoryModal, setShowCategoryModal] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)

  const [categoryVal, setCategoryVal] = useState('')
  const [paramsCategoryId, setParamsCategoryId] = useState(null)

  const [inValidCategory, setInvalidCategory] = useState(false)
  const [inValidCategoryText, setInvalidCategoryText] = useState('')

  const handleChange = e => {
    const value = e.target.value
    setCategoryVal(value)
  };

  const handleShowCategoryModal = (id) => {
    if (id) {
      setParamsCategoryId(id)
      dispatch(getDetailCategory(id))
        .then((data) => {
          if (data && data.success) {
            console.log(data && data.response && data.response.category_name);
            setCategoryVal(data && data.response && data.response.category_name)
          }
        })
    }

    setShowCategoryModal(true)
  }

  const handleDismissCategoryModal = () => {
    setShowCategoryModal(false)
    setCategoryVal('')
    setParamsCategoryId(null)
    setInvalidCategory(false)
    setInvalidCategoryText('')
  }

  const handleCreateEditCategory = () => {
    if (categoryVal && categoryVal.trim() !== '') {
      if (paramsCategoryId) {
        dispatch(editCategory(paramsCategoryId, {categoryName: categoryVal}))
          .then(({data}) => {
            if (data.success) {
              dispatch(getAllCategory())
              handleDismissCategoryModal()
            }
          })
          .catch((err) => {
            let errMessage = err && err.message
            if (errMessage == 'Category has already!') {
              setInvalidCategory(true)
              setInvalidCategoryText('Category name is has already, please input another category name')
            }
          })
      } else {
        dispatch(createCategory({categoryName: categoryVal}))
          .then(({data}) => {
            if (data.success) {
              dispatch(getAllCategory())
              handleDismissCategoryModal()
            }
          })
          .catch((err) => {
            let errMessage = err && err.message
            if (errMessage == 'Category has already!') {
              setInvalidCategory(true)
              setInvalidCategoryText('Category name is has already, please input another category name')
            }
          })
      }
    } else {
      setInvalidCategory(true)
      setInvalidCategoryText('Please fill this out field')
    }
  }

  const handleShowModalDelete = (id) => {
    setShowModalDelete(true)

    if (showModalDelete) {
      Swal.fire({
        title: 'Are you sure to deleted this category?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#336AFE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteCategory(id))
            .then((({data}) => {
              if (data.success) {
                Swal.fire(
                  'Deleted!',
                  'Your category has been deleted.',
                  'success'
                ).then(() => {
                  dispatch(getAllCategory())
                  setShowCategoryModal(false)
                })
              }
            }))
            .catch(err => {
              let errMessage = err && err.message
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: errMessage,
              });
            })
        } else {
          setShowCategoryModal(false)
        }
      })
    }
  }

  return (
    <div>
      <HeadMeta
        title='List Category'
        description='Manage categories'
        siteName="Cloudxier"
        url={window.location.href}
        image={CloudxierLogoSquare}
      />
      <Sidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-white">
        <Header props={props} />
        <div className="body flex-grow-1 px-3">
          <CContainer className="p-5">
            <h3 className='mb-3'>List Category</h3>
            <div className='row row-cols-3 g-3 mb-3'>
              {
                categories && Array.isArray(categories) && categories.length > 0 && categories.map((item, i) => {
                  return (
                    <div className='d-flex mb-2' key={i}>
                      <CForm className='row g-3'>
                        <CCol xs="auto">
                          <CFormInput 
                            type="text" 
                            id="category" 
                            placeholder="Category"
                            value={item.category_name}
                            disabled={true}
                          />
                        </CCol>
                        <CCol xs="auto" className='d-flex align-items-center'>
                          <span className="toggle-icon me-2" onClick={() => handleShowCategoryModal(item.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                              <path fillRule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
                            </svg>
                          </span>
                          <span className="toggle-icon" onClick={() => handleShowModalDelete(item.id)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash-fill" viewBox="0 0 16 16">
                              <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5M8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5m3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0"/>
                            </svg>
                          </span>
                        </CCol>
                      </CForm>
                    </div>
                  );
                })
              }
            </div>
            <CButton type="submit" className='px-4' color="dark" variant='outline' shape="rounded-pill" onClick={() => handleShowCategoryModal()}>
              Add Category
            </CButton>
            <CModal
              alignment="center"
              visible={showCategoryModal}
              onClose={handleDismissCategoryModal}
            >
              <CModalHeader>
                {
                  paramsCategoryId ? (
                    <CModalTitle>Edit Category</CModalTitle>
                  )
                  : (
                    <CModalTitle>Create Category</CModalTitle>
                  )
                }
              </CModalHeader>
              <CModalBody>
                <CForm className="row">
                  <CCol>
                    <CFormInput 
                      type="text" 
                      id="category" 
                      placeholder="Category Name"
                      value={categoryVal} 
                      onChange={handleChange}
                      invalid={inValidCategory}
                      feedbackInvalid={inValidCategoryText} 
                    />
                  </CCol>
                </CForm>
              </CModalBody>
              <CModalFooter>
                <CButton color="secondary" onClick={handleDismissCategoryModal}>
                  Close
                </CButton>
                <CButton color="primary" onClick={handleCreateEditCategory}>
                  {
                    paramsCategoryId ? 'Edit' : 'Create'
                  }
                </CButton>
              </CModalFooter>
            </CModal>
          </CContainer>
        </div>
        <Loader show={loading} />
        <Footer />
      </div>
    </div>
  )
}

export default Category