import { Route, Redirect } from "react-router-dom"
import Cookies from "js-cookie"

export default function PrivateRoute({ component: Component, path, ...rest }) {
  const cookie = Cookies.get('clx')
  return (
    <Route
      {...rest}
      render={({ location }) =>
        cookie ? (
          <Component {...rest} />
        ) : (
          path == '/register' ? (
            <Redirect
              to={{
                pathname: `${path}`,
                state: { from: location },
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        ) 
      }
    />
  )
}
