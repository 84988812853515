import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import {
  CContainer,
  CHeader,
  CDropdownToggle,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CNavbar
} from '@coreui/react'

import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

function Header () {
  const history = useHistory()

  const [showLogoutModal, setShowLogoutModal] = useState(false)

  const handleShowLogoutModal = () => {
    setShowLogoutModal(!showLogoutModal)
  }

  useEffect(() => {
    handleShowSwalLogoutModal()
  }, [showLogoutModal])

  const handleShowSwalLogoutModal = () => {
    if (showLogoutModal) {
      Swal.fire({
        title: 'Are you sure to logout?',
        text: "You must be login again!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#336AFE',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Logout'
      }).then((result) => {
        if (result.isConfirmed) {
          Cookies.remove('clx')
          history.push('/login')
        }
      })
    }
  }

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer lg className="mx-5">
        <CNavbar className='w-100'>
          <div className="me-3 w-75">
            
          </div>
          <CDropdown variant="nav-item" popper={true} style={{listStyleType: 'none'}}>
            <CDropdownToggle>
              <span className='toggle-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                  <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
                </svg>
              </span>
            </CDropdownToggle>
            <CDropdownMenu>
              <CDropdownItem onClick={handleShowLogoutModal}>Logout</CDropdownItem>
            </CDropdownMenu>
          </CDropdown>
        </CNavbar>
      </CContainer>
    </CHeader>
  )
}

export default Header