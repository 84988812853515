import { 
  REQUEST_GET_ALL_CATEGORY,
  SUCCESS_GET_ALL_CATEGORY,
  REQUEST_GET_DETAIL_CATEGORY,
  SUCCESS_GET_DETAIL_CATEGORY,
  REQUEST_CREATE_CATEGORY, 
  SUCCESS_CREATE_CATEGORY,
  ERROR_CREATE_CATEGORY,
  REQUEST_EDIT_CATEGORY,
  SUCCESS_EDIT_CATEGORY,
  ERROR_EDIT_CATEGORY,
  REQUEST_DELETE_CATEGORY,
  SUCCESS_DELETE_CATEGORY
} from "./action_types"
import axios from "axios"
import { server } from "../../config/server"

export function requestGetAllCategory(payload) {
  return {
    type: REQUEST_GET_ALL_CATEGORY,
    payload,
  }
}

export function successGetAllCategory(payload) {
  return {
    type: SUCCESS_GET_ALL_CATEGORY,
    payload,
  }
}

export function requestGetDetailCategory(payload) {
  return {
    type: REQUEST_GET_DETAIL_CATEGORY,
    payload,
  }
}

export function successGetDetailCategory(payload) {
  return {
    type: SUCCESS_GET_DETAIL_CATEGORY,
    payload,
  }
}

export function requestCreateCategory(payload) {
  return {
    type: REQUEST_CREATE_CATEGORY,
    payload,
  }
}

export function successCreateCategory(payload) {
  return {
    type: SUCCESS_CREATE_CATEGORY,
    payload,
  }
}

export function errorCreateCategory(payload) {
  return {
    type: ERROR_CREATE_CATEGORY,
    payload,
  }
}

export function requestEditCategory(payload) {
  return {
    type: REQUEST_EDIT_CATEGORY,
    payload,
  }
}

export function successEditCategory(payload) {
  return {
    type: SUCCESS_EDIT_CATEGORY,
    payload,
  }
}

export function errorEditCategory(payload) {
  return {
    type: ERROR_EDIT_CATEGORY,
    payload,
  }
}

export function requestDeleteCategory(payload) {
  return {
    type: REQUEST_DELETE_CATEGORY,
    payload,
  }
}

export function successDeleteCategory(payload) {
  return {
    type: SUCCESS_DELETE_CATEGORY,
    payload,
  }
}

export function getAllCategory() {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestGetAllCategory())
      axios({
        url: `${server()}/categories/`,
        method: "GET",
        withCredentials: true,
      })
      .then(({data}) => {
        if (data && data.success) {
          dispatch(successGetAllCategory(data.response))
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err.response.data)
      })
    })
  }
}

export function getDetailCategory(categoryId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestGetDetailCategory())
      axios({
        url: `${server()}/categories/${categoryId}`,
        method: "GET",
        withCredentials: true,
      })
      .then(({data}) => {
        if (data && data.success) {
          dispatch(successGetDetailCategory(data.response))
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err.response.data)
      })
    })
  }
}

export function createCategory(payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestCreateCategory())
      axios({
        url: `${server()}/categories/create`,
        method: "POST",
        data: payload,
        withCredentials: true,
      })
      .then((data) => {
        if (data) {
          dispatch(successCreateCategory(data))
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(errorCreateCategory(err.response.data))
        reject(err.response.data)
      })
    })
  }
}

export function editCategory(categoryId, payload) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestEditCategory())
      axios({
        url: `${server()}/categories/${categoryId}`,
        method: "PATCH",
        data: payload,
        withCredentials: true,
      })
      .then((data) => {
        if (data) {
          dispatch(successEditCategory(data))
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(errorEditCategory(err.response.data))
        reject(err.response.data)
      })
    })
  }
}

export function deleteCategory(categoryId) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(requestDeleteCategory())
      axios({
        url: `${server()}/categories/${categoryId}`,
        method: "DELETE",
        withCredentials: true,
      })
      .then((data) => {
        if (data) {
          dispatch(successDeleteCategory(data))
          resolve(data)
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err.response.data)
      })
    })
  }
}