import { useState } from 'react'

// React router dom
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

// Screens
import Home from './Home'
import Login from './Login'
import Register from './Register'
import CreateBlog from './CreateBlog'
import Category from './Category';
import PrivateRouter from './PrivateRoute';
import Verify from './Verify';

function Main () {
  return (
    <Router>
      <Switch>
        <Route path='/account/verify/:token' component={Verify} exact />
        <Route path='/account/verify/' component={Verify} exact />
        <PrivateRouter path='/edit/:id' component={CreateBlog} exact />
        <PrivateRouter path='/add-blog' component={CreateBlog} exact />
        <PrivateRouter path='/category' component={Category} exact/>
        <Route path='/register' exact>
          <Register />
        </Route>
        <Route path='/login' exact>
          <Login />
        </Route>
        <PrivateRouter path='/' component={Home} exact />
      </Switch>
    </Router>
  )

}

export default Main